import { GetPortfolioAssetsAsCsvResponse } from '~/networking/api/managed/responses';
import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const getPortfolioAssetsAsCsv =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (portfolioID: string | undefined): Promise<Response<GetPortfolioAssetsAsCsvResponse>> => {
        const useablePortfolioId = portfolioID || getPortfolioId();
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/portfolio/${useablePortfolioId}/wines/csv`);

        const result = await httpRequest.get<GetPortfolioAssetsAsCsvResponse>(httpRequestUrl, { headers });
        return result;
    };
